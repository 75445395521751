import React, { useEffect, useState } from "react";
import { Alert, Button, Grid, MenuItem, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { LegalRequirementDocuments } from "./LegalRequirementDocuments";
import { ILegalRequirement } from "../../../../Interfaces/ILegalRequirement";
import { ILegalTransactionFullObject } from "../../../../Interfaces/ILegalTransaction";
import { LegalInfoText } from "./LegalInfoText";
import { IConfig } from "../../../../Interfaces/IConfig";


interface IProps {
    configObject: IConfig;
    legalTransaction: ILegalTransactionFullObject;
    legalRequirement: ILegalRequirement;
    legalRequirementArray: ILegalRequirement[];
    setLegalRequirementArray: Function;
    isNotDisabled: boolean;
}


export const LegalRequirementRow:React.FC<IProps> = (props) => {
    const [legalRequirement, setLegalRequirement] = useState(props.legalRequirement);

    const getCurrentValue = () => {
        if (legalRequirement.isComplete) 
            { return 1 }
        else if (legalRequirement.hasError)
            { return 2 }
        else
            { return 0 }
    }

    const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tmpValue = Number(event.target.value)

        if (tmpValue === 0) {
            setLegalRequirement({...legalRequirement, isComplete: false, hasError: false})
        }
        else if (tmpValue === 2 && legalRequirement.enableError) {
            setLegalRequirement({...legalRequirement, 
                isComplete: false, 
                hasError: true
            })
        }
        else {
            setLegalRequirement({...legalRequirement, 
                isComplete: true
            })
        }
    }

    useEffect(() => {
        props.setLegalRequirementArray([
            ...props.legalRequirementArray.map(x => 
                x.idLegalRequirement === legalRequirement.idLegalRequirement
                ? legalRequirement : x
            )
        ])
    },[legalRequirement])

    return(
        <>
            <Grid item xs={2} sm={3} lg={1}></Grid>

            <Grid item xs={10} sm={9} lg={11}>
                <Alert 
                
                variant={ (props.isNotDisabled) ? "standard" : "outlined"}
                severity={
                    (legalRequirement.idLegalRequirementState === 40) ? "success"
                    : (legalRequirement.enableError && legalRequirement.idLegalRequirementState === 30) ? "error" 
                    : (legalRequirement.idLegalRequirementState === 20) ? "warning"
                    : "info"
                }
                action={
                    <>
                        <LegalInfoText currentObject={legalRequirement}/>
                        <Typography variant="caption" >
                        {
                            legalRequirement.LegalRequirementState
                        /*(props.isNotDisabled) 
                            ?
                                <TextField
                                    label="Status"
                                    size="small"
                                    value={getCurrentValue()}
                                    onChange={handleChangeValue}
                                    sx={{width: 200}}
                                    select
                                >
                                    <MenuItem value={0}>Offen</MenuItem>
                                    <MenuItem value={1}>Erledigt</MenuItem>
                                    <MenuItem value={2} disabled={!legalRequirement.enableError}>Fehler</MenuItem>
                                </TextField>
                            : <i style={{marginTop: 2}}>Keine Änderungen möglich</i>
                        */}
                        </Typography>
                    </>
                  }
                >
                    {legalRequirement.LegalRequirement}
                    <LegalRequirementDocuments
                        configObject={props.configObject}
                        legalTransactionObject={props.legalTransaction}
                        legalRequirementObject={legalRequirement}
                        setLegalRequirementObject={setLegalRequirement}
                        isNotDisabled={props.isNotDisabled}
                    />
                    {(legalRequirement.sendMail == true) && <Typography variant="caption">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(E-Mail wird versendet)</Typography>}


                </Alert>

            </Grid>
        </>
    )
}